body {
    background: var(--main-bg);
    font-family: "Roboto";
    overflow-x: hidden;
    margin: 0px;
}

html {
    overflow-x: hidden;
}

#header {
    background: var(--main-bg);
    border-bottom: 12px solid var(--second-bg);
    padding: 0px 15px;
    color: var(--second-tc);
}

#header .col-xs-12 {
    margin: 0 auto;
}

#header #firstPart {
    width: 200px;
}

#header #secondPart {
    width: 400px;
}

.sitebox {
    max-width: 1920px;
}

#topLogo {
    width: 77px;
    margin-top: 9px;
}

div#cookieShow {
    background: var(--third-bg);
    padding: 30px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: auto;
}

.cookieText {
    font-size: 24px;
    margin-bottom: 30px;
}

.menu li:hover,
#loginitem:hover {
    font-weight: 700;
    cursor: pointer;
}

.redColor {
    filter: invert(10%) sepia(100%) saturate(7146%) hue-rotate(358deg) brightness(90%) contrast(112%);
}

.redBorder {
    border-color: var(--error)!important;
}

.errorMessage {
    color: var(--error);
    font-size: 24px;
    margin-top: -10px;
    margin-bottom: 12px;
}

div#formWrapper label,
div.formWrapper label {
    font-size: 20px;
}

.footerWrapper img#topLogo {
    margin-top: 10vh;
    width: 120px;
    max-width: 100%;
}

.menu {
    padding: 0px;
}

.menu li {
    display: inline-block;
    color: var(--second-tc);
    font-size: 1.6em;
    margin-right: 29px;
}
a:-webkit-any-link {
    color: var(--second-tc);
    text-decoration: none;
}

.menu li img,
img.headericon {
    margin-right: 5px;
    vertical-align: middle;
}

div#loginitem {
    font-size: 1.6em;
    text-align: right;
    text-align: right;
    padding-top: 15px;
}

#mobileMenu {
    background: var(--fourth-bg);
    padding: 0px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: none;
    z-index: 2000;
}

ul.mobileMenuList {
    padding: 0px;
    list-style: none;
    text-align: center;
}

ul.mobileMenuList li {
    display: inline-block;
    width: 19%;
}

div#tiszteltPart {
    background: url(../../assets/images/hatterHomeTop.svg) top center no-repeat var(--main-bg);
    color: var(--second-tc);
    background-size: cover!important;
}

h2 {
    font-family: "Ruslan Display";
    font-size: 2.2em;
}

div#tiszteltTextBox {
    max-width: 80%;
    font-size: 1.7em;
    font-size: 28px;
}

#tiszteltPart article {
    margin-top: 16vh;
    margin-left: 7vw;
    margin-bottom: 12vh;
}

.topRightWrapper {
    position: relative;
    overflow: hidden;
}

#tiszteltImage {
    max-width: 100%;
    position: absolute;
    width: 100%;
    min-height: 100%;
}

div.titlePart {
    background: url(../../assets/images/titleLines.svg) center center no-repeat var(--main-bg);
    color: var(--third-tc);
    text-align: center;
    background-size: cover;
}

.titlePart.titlePart2 {
    background: var(--third-bg);
    color: var(--main-tc);
    padding-top: 9px;
}

.titlePart2 h2 {
    padding: 12px;
    margin: 0px;
    text-align: center;
}

.szIdWrapper img {
    max-width: 100%;
    width: 100%;
}

#pageMain {
    position: relative;
}

.szamlazasiIdoszakWrapper {
    background: url(../../assets/images/hatterFeherSarga.svg) bottom center no-repeat var(--third-bg);
    padding: 60px;
    padding-top: 0px;
    text-align: center;
}

div#regisztralj {
    padding: 50px;
    background: url(../../assets/images/nyomFekete.svg?a=432) top center no-repeat var(--second-bg);
    background-size: cover;
}

.loginRegSection {
    padding: 50px;
    background: url(../../assets/images/nyomFekete.svg?a=432) top center no-repeat var(--second-bg);
    background-size: cover;
    background-position: 20% -6%;
    position: relative;
}

div#regisztraljFeherBack {
    background: #ffffffd1;
    padding: 50px;
    max-width: 75%;
    margin: 0 auto;
    border-radius: 7px;
}
#sectionRegisztralj label {
    float: left;
} 
#regisztraljFeherBack.blackWindow {
    background: #000000eb;
}

#regisztraljFeherBack div#formWrapper {
    margin-bottom: 2vw;
}

.clearBoth {
    clear: both;
}

.loginRegOffer {
    color: var(--main-tc);
    text-align: left;
    margin-top: 22px;
    font-size: 22px;
}

.formWrapper button.loginRegCancelButton {
    float: left;
    background: #d0d0d0;
}

.formWrapper button.loginRegForgotPasswordSendButton {
    margin-right: 0px;
}

#forgotPasswordWindow label {
    color: var(--main-tc);
}

#forgotPasswordWindow input.vgInput {
    border-color: var(--main-bg);
    margin: 0 auto;
    margin-bottom: 21px;
}

#regisztralj h3 {
    text-align: center;
    font-size: 2.2em;
    margin-bottom: 0px;
}

#regisztralj p {
    text-align: center;
    font-size: 1.5em;
    margin-top: 5px;
    font-weight: 600;
}

div#regGombok {
    background: #efefef;
    border-radius: 12px;
    width: 380px;
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
}

#regGombok button {
    border: 0px;
    padding: 15px;
    margin: 0px;
    width: 50%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2em;
    border-radius: 8px;
}

#regButton.active {
    background: var(--fourth-bg);
}

#loginButton.active {
    background: var(--fourth-bg);
}

.nagyGomb {
    width: 37%;
    display: inline-block;
    text-align: center;
    background: var(--third-bg);
    padding: 5%;
    box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.25), 8px 8px 16px rgba(0, 0, 0, 0.25);
    opacity: 0.8;
    margin: 0 3px;
    margin-bottom: 20px;
    max-width: 400px;
}

.nagyGomb h2 {
    font-size: 2.4em;
    margin-bottom: 0px;
}

.nagyGomb:hover, .nagyGomb:focus {
    background: var(--second-bg);
}

.nagyGombWrapper {
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
}

div#myAbraWrapper2 {
    position: relative;
}

button.sargaButton {
    background: var(--second-bg);
    border: 0px solid;
    color: var(--main-tc);
    font-family: 'Roboto';
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 10px 20px;
    border-radius: 5px;
}

div#noGarazsContentWrapper {
    margin-top: 20%;
}

.noGarazsText {
    margin: 13px;
    font-weight: 600;
}

.hidden {
    display: none;
}

.drGarazs.garazsFoglalt {
    background: url(../../assets/images/auto-feher.svg) bottom center no-repeat #D0D0D0;
    background-size: contain;
    cursor: default !important;
    pointer-events: none;
}

.drGarazs.onGarazsa {
    background: url(../../assets/images/auto-feher.svg) bottom center no-repeat var(--main-bg);
    background-size: 80%;
    cursor: default !important;
    pointer-events: none;
}

.drGarazs.valasztott {
    background: url(../../assets/images/auto-feher.svg) bottom center no-repeat var(--fourth-bg);
    background-size: 80%;
}

.garazsGombok button {
    float: left;
}

.garazsGombok {
    margin-top: 50px;
}

button.standardButton.torlesButton {
    background: url(../../assets/images/ikon-garazs-torles.svg?a=432) center left no-repeat var(--fourth-bg);
    background-size: 19%;
    background-position: 8%;
}

div#noGarazs {
    position: absolute;
    z-index: 2000;
    background: #ffffffad;
    width: 100%;
    height: 100%;
    text-align: center;
}

div#lepesek {
    margin: 0 auto;
    max-width: 100%;
    background: url(../../assets/images/arrowsBack.svg) bottom center no-repeat;
    background-size: cover;
    background-position-y: 128%;
}

.lepesBlock .downArrow {
    display: none;
}

div#mukodesi {
    padding: 30px;
}

img.lepesImage.lepesImage5 {
    position: relative;
    top: -20%;
}

img.lepesImage.lepesImage6 {
    width: 137px;
    height: 99px;
}

.menu li img,
img.headericon {
    max-width: 35px;
}

img.tipusValaszto {
    max-height: 200px;
}

section#sectionMukodesi {
    color: var(--second-tc);
    background: url(../../assets/images/nyomKetSarga.svg?a=43d32) top center no-repeat var(--main-bg);
    background-size: cover;
}

.lepesekSlide {
    max-width: 880px;
    margin: 0 auto;
    position: absolute;
    top: 0px;
    left: 0px;
}

.lepesekSlide.inactive {
    opacity: 0;
}

.lepesekSlider {
    max-width: 880px;
    margin: 0 auto;
    height: 500px;
    position: relative;
}

.egyLepes img {
    width: 38px;
    float: left;
    margin-right: 13px;
}

.egyLepes h3 {
    font-family: "Ruslan Display";
    font-size: 2.2em;
    margin-bottom: -10px;
}

.egyLepes p {
    font-size: 1.6em;
    line-height: 1.3em;
}

.egyLepes {
    margin-bottom: 40px;
}

div#lepesekLeirasWrapper1 {
    max-width: 880px;
    margin: 0 auto;
    margin-bottom: 50px;
}

#regisztraljFeherBack .myWindow {
    max-width: 1000px;
    margin: 0 auto;
}

h3.loginRegTitle {
    text-align: center;
    font-size: 36px;
    margin-top: 0px;
}

.loginRegSubtitle {
    text-align: center;
    margin-top: -30px;
    font-size: 20px;
    margin-bottom: 30px;
}

.blackWindow label {
    color: var(--second-tc)!important;
}

div#lepesekPoints {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

#lepesekPoints ul {
    list-style: none;
}

#lepesekPoints ul li {
    display: inline-block;
    margin: 0px 3px;
}

.point {
    width: 20px;
    height: 20px;
    background: var(--third-bg);
    border-radius: 50%;
    cursor: pointer;
}

.point.pointSarga {
    background: var(--second-bg);
}

div#kapcsolatWrapper {
    padding-bottom: 50px;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0px 20px;
    padding-bottom: 50px;
}

div#formWrapper,
div.formWrapper {
    color: var(--third-tc);
    font-size: 1.6em;
}

#formWrapper img,
.formWrapper img {
    width: 20px;
    margin-right: 5px;
    vertical-align: middle;
}

button.loginRegRegButton,
button.loginRegLoginButton {
    float: none!important;
    margin: 0 auto!important;
    display: block;
}

div#elerhetosegeinkWrapper {
    color: var(--second-bg);
    font-size: 1.6em;
    margin-top: 140px;
    display: inline-block;
    margin: 0 auto;
    margin-top: 140px;
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.posRel {
    position: relative;
}

img.plusIcon {
    position: absolute;
    top: 51%;
    right: -24px;
}

.vgInput {
    background: var(--third-bg);
    border: 2px solid var(--fourth-bg);
    border-radius: 4px;
    display: block;
    width: 97%;
    padding: 12px;
    margin-top: 6px;
    margin-bottom: 15px;
}

img.plusIcon {
    position: absolute;
    top: 51%;
    right: -31px;
}

.myWindow input.vgInput,
.myWindow textarea.vgInput {
    border-color: var(--main-bg);
}

textarea.vgInput {
    height: 10em;
}

#formWrapper button,
.formWrapper button,
.standardButton {
    background: var(--fourth-bg);
    border-radius: 8px;
    padding: 10px 34px;
    font-weight: 600;
    border-style: solid;
    border-color: transparent;
    float: right;
    margin-top: 10px;
    text-transform: uppercase;
    font-family: 'Roboto';
    cursor: pointer;
}

div#foglalasTorolBox {
    position: absolute;
    background: #000000db;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}

div#foglalasTorolWindow {
    background: var(--second-bg);
    max-width: 1246px;
    width: 100%;
    padding: 30px;
    margin: 0 auto;
    border-radius: 7px;
    margin-top: 20vh;
    text-align: center;
}

div#foglalasTorolWindow h2 {
    font-size: 48px;
}

div#foglalasTorolWindow p {
    font-size: 28px;
}

.buttonWrapper button {
    display: inline-block!important;
    float: none;
}

.szerzodesAlairasWrapper {
    padding: 10px;
    background: url(../../assets/images/nyomKetSarga.svg?a=43254) top center no-repeat var(--main-bg);
    background-size: cover;
}

.szerzodesAlairasWrapper h2 {
    padding-top: 0px;
    margin-top: 0px;
    text-align: center;
}

.noFloatButton {
    float: none;
}

.buttonDisabled {
    background: #D0D0D0;
}

#sectionProfil {
    background: url(../../assets/images/nyomEgySarga.svg) bottom center no-repeat var(--third-bg);
    padding: 20px;
    background-size: cover;
}

.fizIntervalWrapper button.fizInt {
    display: inline-block;
    float: none!important;
    margin-right: 10px!important;
    text-transform: none!important;
    height: 70px;
    background: #D0D0D0!important;
    font-size: 0.9em;
    font-weight: 500!important;
}

.fizIntervalWrapper button.fizInt {
    padding: 7px 8px!important;
}

.fizIntervalWrapper button.fizInt.selected {
    background: var(--fourth-bg)!important;
}

#formWrapper button.adatRefresh {
    margin-right: 0px;
}

#profilWrapper {
    max-width: 1400px;
    display: inline-block;
    margin: 0 auto;
}

#formWrapper.profilSzerkesztes label {
    color:var(--main-tc);
}

#formWrapper.profilSzerkesztes input {
    border-color: var(--main-bg);
}

.alairasWindow {
    background: var(--third-bg);
    max-width: 1598px;
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.alairasText {
    text-align: center;
    font-weight: 500;
    font-size: 1.1em;
}

.alairasPaper {
    padding: 20px;
    border: 4px solid var(--main-bg);
    margin-top: 20px;
    height: 50vh;
    border-radius: 7px;
    text-align: center;
}

.sigCanvas {
    padding: 20px;
    margin-top: 20px;
    height: 40vh;
    text-align: center;
}

.alairasPaper canvas {
    width: 100%!important;
}

section#section404 {
    color: var(--second-tc);
    background: url(../../assets/images/nyomNegysarga.svg?a=4d32) top center no-repeat var(--main-bg);
    background-size: cover;
    padding: 90px 0px;
    text-align: center;
}

.szerzodes404 {
    padding: 0px 20px;
}

#sectionImpresszum {
    background: url(../../assets/images/nyomEgySarga.svg) bottom center no-repeat var(--third-bg);
    padding: 20px;
    background-size: cover;
}

.window404 {
    background: #ffffffe6;
    max-width: 930px;
    display: inline-block;
    margin: 0 auto;
    padding: 30px;
    border-radius: 7px;
}

.button404Wrapper button {
    float: none!important;
    margin-right: 0px;
}

.text404 {
    font-size: 11.8em;
    font-weight: 400;
    color: var(--third-tc);
    text-shadow: 9px -3px 0px var(--fourth-bg);
    margin-bottom: -50px;
}

h2.description404 {
    font-family: 'Roboto';
    color: var(--main-tc);
    font-size: 2.9em;
}

button.adatCancel {
    float: left!important;
    background: #D0D0D0!important;
}

ul#contactList {
    list-style: none;
    margin: 0px;
    padding: 0px;
    font-size: 0.8em;
    margin-top: 30px;
}

ul#contactList li {
    margin-bottom: 1em;
    font-weight: 300;
}

ul#contactList img {
    width: 30px;
    float: left;
    margin-right: 10px;
}

section#sectionKapcsolat,
section#sectionUtalas,
section#hibaBejelentes {
    background: url(../../assets/images/nyomFeherek.svg) top center no-repeat var(--main-bg);
    background-size: cover;
}

section#hibaBejelentes {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0px 20px;
    padding-bottom: 50px;
    text-align: center;
}

.text-left {
    text-align: left;
}

section#sectionUtalas {
    margin: 0px;
    padding: 4vw;
}

div#utalasWrapper {
    max-width: 1246px;
    background: url(../../assets/images/sokAutoHatter.svg) bottom center no-repeat var(--second-bg);
    margin: 0 auto;
    border-radius: 6px;
    text-align: center;
    padding: 20px;
    padding-bottom: 410px;
    background-size: contain;
}

.utalasText {
    font-size: 1.3em;
}

div#hirekWrapper {
    padding: 50px;
    background: url(../../assets/images/nyomFekete.svg) top center no-repeat var(--second-bg);
    background-size: cover;
}

.hirekNav img {
    width: 25px;
    display: inline-block;
}

.hirekNav div {
    display: inline-block;
    position: relative;
    top: -14px;
    font-weight: 500;
    font-size: 1.1em;
    margin: 5px;
}

.hirekNav.hirekNavRight {
    text-align: right;
    margin-top: 10px;
}

.lepesBlock {
    width: 12.5%;
}

.hirekBlock img {
    max-width: 100%;
}

img.lepesImage {
    width: 50%;
    margin: 0 auto;
    display: block;
}

img.lepesImage.inactive {
    opacity: 0.3;
}

.titleWrapper {
    background: var(--third-bg);
    padding: 0px 5px;
    padding-bottom: 10px;
}

.hirekBlock {
    width: calc(100% - 20px) !important;
}

.custom-slick .slick-arrow::before {
    color: black !important
}

.hirekSource {
    background: var(--main-bg);
    color: var(--second-tc);
    padding: 3px;
    font-size: 0.9em;
}

.hirekSource img {
    width: 24px!important;
    margin-right: 4px;
}

.titleWrapper h2 {
    margin: 0px;
    font-family: Roboto;
    font-size: 1.4em;
}

.hirekBlock img {
    width: 100%;
    margin-bottom: -3px;
}

.hideForNow {
    display: none;
}

.kapcsolatText {
    text-align: center;
    color: var(--third-tc);
    font-size: 1.7em;
}

div#mobileRightImage {
    text-align: center;
    display: none;
}

div#mobileRightImage img {
    max-width: 100%;
}

.footerWrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 20px;
    padding-bottom: 50px;
    font-size: 1.3em;
}

.row.footerSocials {
    margin-top: 20px;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 20px;
}

.subFooter {
    color: var(--second-tc);
    padding: 12px 0px;
    text-align: center;
    font-size: 1.1em;
    border-top: 6px solid #e5e500;
}

ul.footerMenuList {
    color: var(--second-tc);
    list-style: none;
    padding: 0px;
}

ul.footerMenuList li {
    vertical-align: middle;
    margin-bottom: 0.5em;
}

.footerMenuList span {
    vertical-align: middle;
}

ul.footerMenuList img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

li.footerLoginLink {
    font-weight: 500;
}

ul#footerContactList span {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.footerMiddleLinks {
    margin-top: 20px;
}

.footerMiddleLinks a {
    color: var(--second-tc);
    display: block;
    line-height: 1.5em;
    text-decoration: none;
}

#footerContactList img {
    width: 20px;
    margin-right: 5px;
}

ul#footerContactList li {
    margin-bottom: 8px;
}

#mainFooter h3 {
    color: var(--second-tc);
}

img.feketeMailImg {
    position: relative;
    top: -2px;
}

ul#footerContactList {
    padding: 0px;
    color: var(--second-tc);
    list-style: none;
    font-size: 0.9em;
}

div#foglalasWrapper {
    background: url(../../assets/images/nyomEgySarga.svg?a=432) bottom center no-repeat var(--third-bg);
    padding: 60px;
    background-size: cover;
    position: relative;
}

div#foglalasWhiteBox {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffffe0;
    z-index: 1000;
    text-align: center;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
}

div#foglalasWhiteBox:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
}

div#foglalasWhiteBoxContent {
    margin-top: 18vh;
    line-height: 2em;
    font-weight: 600;
    font-size: 24px;
}

div#foglalasWhiteBox .standardButton {
    float: none;
    margin: 0px;
    font-size: 20px;
}

.drVizszintes {
    background: var(--main-bg);
    padding: 8px;
}


.drGarazs {
    background: #006633;
    text-align: center;
    color: var(--second-tc);
    position: absolute;
}

.drGarazsInner {
    position: relative;
    height: 100%;
}

.drSorszam {
    position: absolute;
    top: 15%;
    width: 100%;
    font-weight: 500;
    font-size: 0.8em;
}

.drTerulet {
    position: absolute;
    width: 100%;
    top: 55%;
    font-size: 0.8em;
}

.drGarazs.drFelsosor {
    top: 3%;
    width: 58px;
    height: 100px;
}

.drGarazs.drFelsosor {
    top: 3%;
    width: 58px;
    height: 100px;
}

.drGarazs.drBlokk4 {
    right: 279px;
    width: 100px;
    height: 56px;
}

.drGarazs.drJobbsor {
    width: 137px;
    height: 56px;
    right: 20px;
}

.drGarazs.drAlso {
    width: 58px;
    height: 100px;
    right: 450px;
}

.drGarazs.drBlokk5 {
    width: 100px;
    height: 56px;
}

div#b201 {
    left: 60px;
}

div#c243 {
    left: 135px;
}

div#c242 {
    left: 195px;
}

div#c241 {
    left: 255px;
}

div#c239 {
    left: 315px;
}

div#c238 {
    left: 375px;
}

div#c216 {
    left: 450px;
}

div#c216 {
    left: 450px;
}

div#c215 {
    left: 510px;
}

div#c214 {
    left: 570px;
}

div#c213 {
    left: 630px;
}

div#c212 {
    left: 690px;
}

div#c211 {
    left: 750px;
}

div#c210 {
    left: 810px;
}

div#c209 {
    left: 870px;
}

div#c208 {
    left: 930px;
}

div#c207 {
    top: 123px;
}

div#c206 {
    top: 180px;
}

div#c205 {
    top: 237px;
}

div#c204 {
    top: 294px;
}

div#c203 {
    top: 351px;
}

div#c202 {
    top: 408px;
}

div#c201 {
    top: 465px;
}

div#c221 {
    bottom: 200px;
}

div#c220 {
    bottom: 257px;
}

div#c219 {
    bottom: 314px;
}

div#c218 {
    bottom: 371px;
}

div#c217 {
    bottom: 428px;
}

div#c223 {
    bottom: 428px;
}

div#c222 {
    bottom: 257px;
    right: 380px;
}

div#c223 {
    bottom: 200px;
    right: 380px;
}

div#c224 {
    bottom: 22px;
}

div#c225 {
    bottom: 22px;
    right: 509px;
}

div#c226 {
    bottom: 22px;
    right: 568px;
}

div#c237 {
    bottom: 200px;
    left: 314px;
}

div#c236 {
    bottom: 257px;
    left: 314px;
}

div#c235 {
    bottom: 314px;
    left: 314px;
}

div#c234 {
    bottom: 371px;
    left: 314px;
}

div#c233 {
    bottom: 428px;
    left: 314px;
}

div#c227 {
    bottom: 428px;
    left: 415px;
}

div#c228 {
    bottom: 371px;
    left: 415px;
}

div#c229 {
    bottom: 314px;
    left: 415px;
}

div#c230 {
    bottom: 257px;
    left: 415px;
}

div#c231 {
    left: 415px;
    bottom: 200px;
}

div#c232 {
    left: 415px;
    bottom: 143px;
}

div#b215 {
    bottom: 200px;
}

div#b214 {
    bottom: 257px;
}

div#b213 {
    bottom: 314px;
}

div#b212 {
    bottom: 371px;
}

div#b211 {
    bottom: 428px;
}

div#drArrow {
    position: absolute;
    top: 140px;
}

div#foglalasInner {
    max-width: 1400px;
    margin: 0 auto;
}

.abraWrapper {
    background: url(../../assets/images/drBack.svg) top center no-repeat;
    width: 1147px;
    height: 746px;
    position: relative;
    transform: scale(0.8);
    transform-origin: left top;
}

.abraWrapper > div:hover {
    cursor: pointer;
    opacity: 0.8;
}

#grid8AbraWrapper {
    overflow: hidden;
    position: relative;
}

.jelolesCar img {
    width: 60px;
    float: left;
    margin-right: 10px;
}

.jelolesSor {
    margin-bottom: 10px;
}

.jelolesCar.jelolesNyil img {
    width: 34px;
}

.berlesKezdetWrapper {
    background: url(../../assets/images/nyomFeketek.svg) top center no-repeat var(--third-bg);
    padding: 60px;
    padding-top: 0px;
    background-size: cover;
}

.berlesTablaWrapper {
    max-width: 1562px;
    padding: 20px;
    background: #ffff00d9;
    margin: 0 auto;
    position: relative;
}

.calendarArrow img {
    width: 40px;
}

.calendarArrow {
    position: absolute;
    top: 40%;
    left: 33%;
}

.calendarHonap {
    font-family: "Ruslan Display";
    font-size: 1.6em;
}

.someNumberThere {
    text-align: center;
    padding: 5px 0px;
    font-weight: 500;
}

.text-center {
    text-align: center;
}

.posRel {
    position: relative;
}

.sevens {
    display: flex;
}

.day {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
}

.day.dayName {
    text-align: center;
    font-weight: 600;
    padding: 10px 0px;
    height: auto;
    background: transparent;
}

.day {
    background: var(--third-bg);
    height: 13vh;
    text-align: center;
    padding: 8px 1px;
    cursor: pointer;
}

.day.selected {
    background: transparent;
}

h3.headingAraink,
h3.headingJeloleseink {
    font-weight: 500;
    font-size: 1.4em;
}

.jeloleseinkWrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 360px;
}

.drBlokk6 {
    width: 100px;
    height: 56px;
    left: 90px;
}

.drGarazs.drAlso .drTerulet {
    font-size: 0.6em;
}

.szerzodesWrapper {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0px 20px;
    padding-bottom: 50px;
}

section#sectionSzerzodes {
    background: var(--third-bg);
}

section#sectionSzerzodes ul {
    list-style: none;
}

section#sectionSzerzodes p {
    line-height: 1.4em;
}

section#sectionSzerzodes ul li {
    margin-bottom: 0.4em;
}

section#sectionSzerzodes.aszfWrapper,
section#sectionSzerzodes.adatkezelesWrapper,
section#sectionSzerzodes.cookieWrapper,
#sectionAszf {
    padding: 20px 0px;
}

ul#footerSocial {
    list-style: none;
    padding: 0px;
}

.signatureLine {
    background: var(--main-bg);
    padding: 2px;
    max-width: 320px;
    margin: 0 auto;
    margin-bottom: 10px;
}

#sectionAszf {
    background: var(--third-bg);
}

.szerzodesAlairasLeft,
.szerzodesAlairasRight {
    text-align: center;
}

.signatureName {
    font-weight: 600;
}

.szerzodesAlairasPart {
    margin-top: 80px;
}

.szerzodesKetGomb button {
    border: 0px;
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Roboto';
    margin: 10px;
    padding: 10px 25px;
    border-radius: 4px;
}

.szerzodesAdatmodositasWrapper {
    padding: 90px;
    background: url(../../assets/images/nyomFekete.svg) top center no-repeat var(--second-bg);
    background-size: cover;
    background-position: 1% 98px;
}

.modositasWindow {
    max-width: 1245px;
    padding: 100px;
    background: #ffffffd1;
    margin: 0 auto;
}

.myWindow h2 {
    margin-top: 0px;
    text-align: center;
}

.myWindow label {
    color: var(--main-tc);
}

#profilWrapper {
    max-width: 1400px;
    display: block;
    margin: 0 auto;
}

.profilePic img {
    width: 140px;
}

.profilePicWrapper {
    width: 140px;
    display: inline-block;
    position: relative;
}

.newProfilePic {
    position: absolute;
    bottom: 10px;
    right: 0px;
}

.newProfilePic img {
    width: 40px;
}

.profileEditPic img {
    max-width: 298px;
}

.profileEditPicWrapper {
    position: relative;
    max-width: 298px;
    margin: 0 auto;
}

.profileEditChangePic {
    position: absolute;
    bottom: 5%;
    right: 2%;
}

.profileName {
    font-size: 1.6em;
    font-weight: 500;
    margin-top: 10px;
}

.ikonSzovegIkon {
    float: left;
    margin-right: 6px;
    position: relative;
    top: -3px;
}

.ikonSzovegPart {
    display: block;
    margin-bottom: 10px;
    clear: both;
    margin-bottom: 26px;
}

.ikonSzovegAdat {
    font-size: 1.5em;
    clear: both;
}

.profilIkonok img {
    width: 70px;
}

.bold {
    font-weight: 700;
}

.ikonSzovegIkon img {
    width: 30px;
}

.ikonSzovegSzoveg {
    font-size: 1.2em;
}

button.gombAdatokModositasa {
    background: #D0D0D0;
}

button.gombAlairas {
    background: var(--fourth-bg);
}

.szerzodesKetGomb {
    text-align: center;
}

h1,
h2,
h3,
h4,
h5 {
    word-wrap: break-word;
}

h3.subTitle {
    color: var(--fourth-bg);
    margin-top: -1em;
    font-size: 1.5em;
}

div#impresszumWrapper {
    padding-bottom: 10vw;
    max-width: 95%;
}

.kapcsolatMap img {
    max-width: 100%;
    text-align: center;
}

.kapcsolatSuccessWindow {
    background: url(../../assets/images/carOne.svg) center center no-repeat #ffffffe6;
    max-width: 930px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 7px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.kapcsolatSuccessWindow button.standardButton {
    float: none;
    margin: 0 auto;
    display: block;
    margin-bottom: 21vw;
    font-size: 1.3em;
    margin-top: 10px;
}

.aszfWrapper .szerzodesWrapper>ul>li {
    font-weight: 600;
}

.aszfWrapper .szerzodesWrapper>ul>li>ul>li {
    font-weight: 400;
}

section#sectionRegSuccess {
    background: url(../../assets/images/nyomFekete.svg?a=4d32) top center no-repeat var(--second-bg);
    background-size: cover;
    padding: 130px 0px;
    text-align: center;
}

.myWhiteWindow {
    background: #ffffffe0;
    max-width: 772px;
    margin: 0 auto;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
}

.myWhiteWindow h3 {
    font-size: 1.5em;
}

img.myBoritek {
    max-width: 60%;
    margin-top: 20px;
}

.windowText {
    font-size: 1.5em;
    max-width: 70%;
    margin: 0 auto;
}

button {
    font-family: "Roboto";
}

.kapcsolatMap {
    margin-top: 30px;
}

img.hibaKep {
    margin-bottom: 20px;
}

button.adatTovabb {
    margin-right: 0px!important;
}

.inlineInput {
    display: inline-block;
}

h2.headerTitle {
    text-align: right;
}

.lostPasswordImage {
    display: block;
    margin: 0 auto;
}

img.lostPasswordImage {
    margin-bottom: 20px;
}

a.newPasswordLink {
    font-size: 0.9em;
    color: var(--main-tc);
}

div#loginRegForgotPasswordBox {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000d9;
    top: 0px;
    left: 0px;
    z-index: 100;
}

div#forgotPasswordWindow {
    background: var(--third-bg);
    padding: 60px;
    max-width: 772px;
    margin: 0 auto;
    border-radius: 7px;
    position: relative;
    margin-top: 10vh;
}

.closeX {
    position: absolute;
    right: 20px;
    top: 20px;
}

#forgotPasswordWindow h3 {
    text-align: center;
    font-size: 36px;
    margin-top: 0px;
    margin-bottom: 11px;
}

#forgotPasswordWindow .loginRegSubtitle {
    font-size: 28px;
    max-width: 340px;
    margin: 0 auto;
    margin-bottom: 15px;
}

button.standardButton.signatureDoneButton {
    margin-right: 0px;
}

#cookieShow h2 img {
    margin-right: 19px;
    position: relative;
    top: 5px;
}

.footerMobile {
    display: none;
}

input.vgInput.inlineHalfInput {
    max-width: 94%;
}

select.vgInput {
    width: 100%;
}

.row.footerSocials img {
    max-width: 100%;
}

.mukodesiMobile {
    display: none;
}

div#toTheTop {
    position: fixed;
    width: 60px;
    height: 40px;
    bottom: 40px;
    right: 20px;
    z-index: 555;
}

#mobileMenu li a:hover, #mobileMenu li a:focus {
    background: var(--main-bg);
}

#mobileMenu li a {
    display: inline-block;
    padding: 6px;
    border-radius: 6px;
}
/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    #regGombok button {
        width: auto;
        margin: 0 auto;
    }
    #regGombok {
        width: auto!important;
    }
    #regisztralj h3 {
        font-size: 1.8em;
    }
    #tiszteltTextBox p {
        font-size: 0.9em;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (max-width: 600px) {
    div#grid8AbraWrapper {
        margin-bottom: -160px;
    }
    div#regGombok {
        background: transparent;
    }
    div#regGombok button {
        margin-bottom: 15px;
    }
    .nagyGomb h2 {
        font-size: 1.2em;
        margin-top: 0.5em;
        margin-bottom: 0em;
    }
}

@media only screen and (max-width: 500px) {
    div#myAbraWrapper2 {
        left: 220px!important;
    }
    .nagyGomb h2 {
        font-size: 1.4em!important;
    }
    div#regisztraljFeherBack {
        max-width: 95%;
        padding: 15px;
        padding-bottom: inherit!important;
    }
    h2 {
        font-size: 1.4em!important;
    }
}
/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (max-width: 768px) {
    div#tiszteltPart {
        background: url(../../assets/images/hatterHomeTop2.svg) top center no-repeat var(--main-bg);
    }
    #forgotPasswordWindow {
        padding: 30px!important;
        width: 80%;
    }
    #forgotPasswordWindow .loginRegSubtitle {
        font-size: 21px;
        margin-bottom: 20px;
    }
    .loginRegSection {
        padding: 20px;
    }
    .day.dayName {
        font-size: 0.7em;
    }
    .text404 {
        font-size: 8.2em;
    }
    h2.description404 {
        font-size: 2.1em;
    }
    div#regisztralj {
        padding: 30px;
    }
    h2 {
        font-size: 1.8em;
    }
    div#tiszteltTextBox {
        font-size: 1.2em;
        line-height: 1.4em;
        font-weight: 300;
    }
    .nagyGomb {
        width: 77%;
    }
    div#myAbraWrapper2 {
        transform: rotate(-90deg);
        position: relative;
        left: 230px;
    }
    .drSorszam {
        transform: rotate(90deg);
        position: relative;
        top: 20px;
        left: 20px;
    }
    .drTerulet {
        transform: rotate(90deg);
        position: relative;
        top: 7px;
        ;
    }
    div#utalasWrapper {
        padding-bottom: 200px!important;
    }
    .nagyGomb h2 {
        font-size: 1.6em!important;
    }
}

@media only screen and (max-width: 370px) {
    div#myAbraWrapper2 {
        left: 250px;
    }
    div.titlePart:not(div.titlePart2) {
        background: var(--main-bg)!important;
    }
}

@media only screen and (max-width: 1440px) {
    .nagyGomb h2 {
        font-size: 1.7em;
    }
    div#tiszteltTextBox {
        font-size: 20px!important;
    }
    #tiszteltPart article {
        margin-top: 12vh;
        margin-bottom: 7vh;
    }
}

@media only screen and (max-width: 1600px) {
    div#tiszteltTextBox {
        font-size: 23px;
    }
}
/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (max-width: 992px) {
    .egyLepes h3 {
        font-size: 1.2em!important;
    }
    div.titlePart:not(div.titlePart2) {
        background: url(../../assets/images/titleLines2.svg) center center no-repeat var(--main-bg);
        background-size: cover;
    }
    .kapcsolatSuccessWindow {
        padding-bottom: 24%;
        background-size: 50%;
        background-position: bottom center;
    }
    h2.headerTitle {
        font-size: 1.5em;
    }
    #mobileMenu {
        display: block;
    }
    #header .menu,
    #header #loginitem,
    #header {
        display: none;
    }
    ul#contactList {
        font-size: 0.7em;
    }
    body {
        padding-bottom: 120px;
    }
    .berlesKezdetWrapper {
        padding: 60px 0px;
    }
    div#utalasWrapper {
        padding-bottom: 250px;
    }
    .nagyGomb h2 {
        font-size: 1.4em;
    }
}

@media only screen and (max-width: 1200px) {
    div#mobileRightImage {
        display: block;
    }
    div#tiszteltTextBox {
        max-width: 90%;
    }
    #header .menu li,
    #loginitem {
        font-size: 1.1em!important;
    }
    .nagyGomb h2 {
        font-size: 2.5vw;
    }
    img#tiszteltImage {
        height: 100%;
    }
    .cookieText {
        font-size: 19px;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .cookieButtonWrapper1 button {
        float: right!important;
    }
}

@media only screen and (min-width: 1440px) {
    .col-xs-12.col-md-3.profilIkonokCol {
        position: absolute;
        right: 30px;
    }
    .profilIkonok img {
        display: block;
    }
    img.profilKilepesIkon {
        margin-top: 40vh;
    }
}

@media only screen and (max-width: 1023px) {
    #toTheTop {
        bottom: 114px!important;
    }
    ul.lepesekMobileList {
        display: block;
        clear: both;
        margin-top: 30px;
        max-width: 80%;
        font-size: 16px;
        line-height: 1.5em;
    }
    .mukodesiMobile h3 {
        font-size: 1.6em!important;
    }
    .mukodesiMobile {
        display: block;
    }
    .mukodesiDesktop {
        display: none;
    }
    .fizIntervalWrapper button.fizInt {
        padding: 5px 7px!important;
        font-size: 19px;
    }
    .footerMobile li {
        margin-bottom: 17px!important;
    }
    .subFooterContent {
        font-size: 1.5em;
    }
    .elerhetosegekWrapper {
        display: inline-block;
        text-align: left;
    }
    .elerhetosegekCol {
        text-align: center;
    }
    .footerDesktop {
        display: none;
    }
    .footerMobile {
        display: block;
    }
    #cookieShow h2 {
        font-size: 1.2em;
    }
    #cookieShow h2 img {
        width: 25px;
    }
    #cookieShow button {
        margin: 0 auto!important;
        float: none!important;
        margin-bottom: 20px!important;
    }
    #lepesek .lepesBlock {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }
    #lepesek .lepesBlock .downArrow {
        display: block;
        margin: 0 auto;
        width: 45px;
    }
    #lepesek {
        background: transparent!important;
    }
    .egyLepes p {
        font-size: 1.2em;
    }
    #lepesek .lepesBlock img {
        height: 40px;
    }
    #cookieShow {
        padding-bottom: 120px!important;
    }
    .regisztracio3 .myWindow input.vgInput,
    .regisztracio3 .myWindow textarea.vgInput {
        width: 93%!important;
    }
    img.plusIcon {
        right: -29px;
    }
    .day {
        height: 10vh;
    }
    div#foglalasTorolWindow {
        max-width: 85%;
        padding: 2%;
        margin-top: 10vh;
    }
    div#foglalasTorolWindow h2 {
        font-size: 31px!important;
    }
    div#foglalasTorolWindow p {
        font-size: 18px;
    }
    #loginRegLogin h3 {
        margin-top: 50px;
        ;
    }
    div#tiszteltPart {
        background-position: top left;
    }
    img#tiszteltImage {
        height: 100%;
    }
    #mobileRightImage {
        display: block;
    }
    #tiszteltImage {
        display: none;
    }
    #hibaBejelentes .col-md-6 {
        text-align: center!important;
    }
    .calendarArrow {
        position: static;
    }
    .alairasGombok button,
    .standardButton,
    #formWrapper button,
    .formWrapper button {
        float: none!important;
        text-align: center;
        display: block;
        margin: 10px auto!important;
    }
    #formWrapper label,
    .formWrapper label,
    .myWindow label {
        font-size: 22px!important;
    }
    .szerzodesAdatmodositasWrapper {
        padding: 20px;
    }
    .myWindow {
        padding: 20px!important;
    }
}