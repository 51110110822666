.multipleInputContainer {
    display: flex;
    justify-content: space-between;
}

.multipleInputButtonsContainer {
    display: flex;
}

.multipleInputButtonsContainer button {
    width: 50px;
    height: 24px;
    margin: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multipleInputButtonsContainer button:disabled {
    background-color: grey;
}